/* Calendar.css */
.table-container {
    /* background: black; */
    height: 100%;
    margin-left: 150px;
}
.container {
    height: 90vh;
    overflow: auto;
  }

  .MuiTableCell-root-2 {
    font-family: "Poppins", sans-serif;
    /* font-weight: bold; */
    font-size: 14px;
    color: black;
    margin-top: 10px;
}

  /* .MuiTableCell-root {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 10px;
}
   */
  /* In your Calendar.css */
.table-container .MuiTableCell-root {
  border-right: 1px solid #ccc;
}

.table-container .MuiTableCell-root:last-child {
  border-right: none;
}

  /* In your Calendar.css */
/* .jobTime {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  background-color: 'red';
  color: 'white';
  padding: 0;
  height: '100%';
  width: '100%';
  border: 'none';
} */

body.dark-mode {
  background-color: #2c2c2c;
  color: #ffffff;
}

.dark-mode .table-container {
  background-color: #424242;
}

.dark-mode .MuiTableCell-root {
  border-bottom: 1px solid #ffffff;
  background-color: #424242;
}

.dark-mode .MuiTooltip-popper {
  color: #ffffff;
}

.dark-mode .MuiTooltip-tooltip {
  background-color: #424242;
  border: 1px solid #ffffff;
}


.dark-mode-bg {
  background-color: #424242;
}

  /* Desktop view */
  @media (min-width: 1024px) {
    .container {
      grid-template-columns: 1fr repeat(7, minmax(100px, 1fr));
      grid-template-rows: auto auto;
      margin-left:
    }
  }
    .navbar-desktop {
        grid-column: 1;
        grid-row: 1 / span 2;
        height: 100%;
    }
  
  
  /* Tablet view */
  @media (max-width: 1023px) and (min-width: 768px) {
    .container {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  
  /* Mobile view */
  @media (max-width: 767px) {
    .container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .grid-item {
    border: 1px solid black;
    padding: 8px;
    background-color: #f0f0f0;
    text-align: center;
  }
  
  .navbar-desktop {
    grid-column: 1;
  }
  
  .extra-space {
    grid-column: 2 / span 7;
  }
  
  .navbar-mobile {
    grid-column: 1 / span 3;
    grid-row: 1;
  }
  
  .navbar-tablet {
    grid-column: 1 / span 5;
    grid-row: 1;
  }
  